<template>
  <div class="app">
    <div class="top">
      <img class="top-bg" src="./assets/top-bg.png" alt :class="isscroll?'':'opacity'" />
      <img class="top-img" src="./assets/top-img.png" alt />
      <div class="top-list">
        <div v-for="(val,i) in topList" :key="i" @click="top(i)" style="cursor: pointer;">{{val}}</div>
      </div>
    </div>
    <img class="img" src="./assets/bg.png" alt />
    <div class="title">“橙”就你我，从青橙开始</div>
    <img class="phone" src="./assets/phone.png" alt />
    <div class="function">
      <div class="function-list">
        <div v-for="(val,i) in functionList" :key="i" class="function-li">
          <div class="function-img-box">
            <img :class="i===4?'function-img1':'function-img'" :src="val.img" alt />
          </div>

          <div class="function-word">
            <div style="font-size:18px; font-weight:bold ;">{{val.name}}</div>
            <div style="font-size: 12px;">{{val.txt}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="market">
      <div class="market-list">
        <div v-for="(val,i) in marketList" :key="i" class="market-li">
          <img :src="val.img" alt style="width: 50px;height: 50px;margin-bottom:17.5px" />
          <div>{{val.name}}</div>
        </div>
      </div>
    </div>
    <div class="contact">
      <div class="contact-word">
        <div>公司地址：武汉市洪山区烽火创新谷1号楼403</div>
        <div>联系电话：13476079008</div>
        <div>邮箱：royliang@orangerunning.com</div>
        <div @click="open">
          备案号：
          <div class="click"> 鄂ICP备2022020340号-2</div>
        </div>
      </div>
      <div style=" text-align: center;">
        <img src="./assets/contact.png" alt style="width: 84px;height: 81px;" />
        <div style="font-size: 12px;transform: scale(0.75,0.75);">加入我们，让梦想成真</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      isscroll: true,
      topList: ["首页", "功能", "市场合作", "联系我们"],
      functionList: [
        {
          img: require("@/assets/function1.png"),
          name: "用户沉淀",
          txt: "游戏玩法、积分兑换，多种营销呈现，让触达更立体",
        },
        {
          img: require("@/assets/function2.png"),
          name: "数据分析",
          txt: "多项数据指标与分析，实时观测运营效果",
        },
        {
          img: require("@/assets/function3.png"),
          name: "新品付费",
          txt: "秒杀、抢购、ip联名，自由组合，全面放大品牌价值",
        },
        {
          img: require("@/assets/function4.png"),
          name: "营销推广",
          txt: "等多种营销利器并行，助力裂变传播",
        },
      ],
      marketList: [
        {
          img: require("@/assets/market1.png"),
          name: "社群营销",
        },
        {
          img: require("@/assets/market2.png"),
          name: "品牌推广",
        },
        {
          img: require("@/assets/market3.png"),
          name: "联合推广",
        },

        {
          img: require("@/assets/market4.png"),
          name: "媒体报道合作",
        },
        {
          img: require("@/assets/market5.png"),
          name: "知识产权合作",
        },
        {
          img: require("@/assets/market6.png"),
          name: "资源互推合作",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scroll, true);
  },
  methods: {
    scroll(e) {
      if (e.srcElement.scrollingElement.scrollTop > 90) {
        this.isscroll = false;
      } else {
        this.isscroll = true;
      }
    },
    top(i) {
      if (i === 0) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (i === 1) {
        window.scrollTo({ top: 411, behavior: "smooth" });
      } else {
        window.scrollTo({ top: 740, behavior: "smooth" });
      }
    },
    open() {
      window.location.href = "https://beian.miit.gov.cn";
    },
  },
};
</script>

<style>
.app {
  width: 960px;
  margin: 0 auto;
  position: relative;
}
.img {
  width: 960px;
  height: 501px;
}

.top {
  position: fixed;
  top: 0;
  height: 90px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
}
.top-bg {
  width: 960px;
  height: 90px;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
}
.opacity {
  opacity: 1;
}
.top-img {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 233.5px;
  top: 12.5px;
}
.top-list {
  position: absolute;
  left: 290px;
  top: 12.5px;
  width: 279px;
  font-size: 10px;
  height: 40px;
  line-height: 40px;
  font-weight: 500;
  color: #cc5a00;
  display: flex;
  justify-content: space-between;
}
.title {
  position: absolute;
  top: 195.5px;
  left: 262.5px;
  font-size: 28px;
  color: #000;
}
.phone {
  position: absolute;
  top: 110px;
  right: 170.5px;
  width: 141px;
  height: 305px;
}
.function {
  width: 960px;
  height: 326px;
  background: #f8f8f8;
  padding-top: 47px;
  box-sizing: border-box;
}
.function-list {
  display: flex;
  width: 689px;
  height: 209px;

  margin-left: 138px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}
.function-li {
  display: flex;
  justify-content: center;
  align-items: center;
}
.function-img-box {
  width: 67px;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.function-img {
  height: 62px;
}
.function-img1 {
  height: 67px;
}
.function-word {
  width: 195px;
  margin-left: 24.5px;
}
.market {
  width: 100%;
  height: 194.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.market-list {
  width: 685px;
  display: flex;
  justify-content: space-between;
}
.market-li {
  text-align: center;
  font-size: 12px;
}
.contact {
  width: 960px;
  height: 145px;
  background-image: url("./assets/bottom.png");
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-word {
  margin-right: 30px;
  font-size: 12px;
}
.click {
  cursor: pointer;
  display: inline;
  border-bottom:1px solid #000
}
</style>
